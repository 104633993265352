import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommandQueryService } from '@base-frontend/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TemplateService } from '../../services/template';
import { IDisplayNames } from './symbols';

@Injectable({
  providedIn: 'root',
})
export class DisplayNameService {
  private readonly _server = 'Performance';

  private _templateService = inject(TemplateService);
  private _commandQueryService = inject(CommandQueryService);

  private _displayNames$ = new BehaviorSubject<IDisplayNames>({});
  displayNames$ = this._displayNames$.asObservable();

  private _regexPattern: RegExp | null = null;

  constructor() {
    this.getDisplayNames()
      .pipe(takeUntilDestroyed())
      .subscribe(displayNames => {
        this._displayNames$.next(displayNames);
        this._regexPattern = this.createRegexPattern(displayNames);
      });
  }

  get displayNames() {
    return this._displayNames$.getValue();
  }

  getDisplayValue(value: string | null) {
    if (!value) {
      return value;
    }

    return this.displayNames[value] ?? this.replaceAllOccurrencesInString(value) ?? value;
  }

  private replaceAllOccurrencesInString(value: string | null) {
    return this._regexPattern
      ? value.replace(this._regexPattern, match => this.displayNames[match])
      : value;
  }

  private createRegexPattern(displayNames: IDisplayNames) {
    // Need this incase the given name contains regex special characters
    const escapeRegExp = (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    return Object.keys(displayNames).length > 0
      ? new RegExp(
          Object.keys(displayNames)
            .map(name => `(?<!\\S)${escapeRegExp(name)}(?!\\S)`)
            .join('|'),
          'g',
        )
      : null;
  }

  private getDisplayNames(): Observable<IDisplayNames> {
    return this._templateService.templateId$.pipe(
      switchMap(templateId =>
        this._commandQueryService
          .sendQuery<IDisplayNames>(this._server, {
            Key: 'ToolDisplayNames',
            TemplateId: templateId,
          })
          .pipe(catchError(() => of({} as IDisplayNames))),
      ),
    );
  }
}
